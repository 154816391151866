<template>
  <div class="tab-pane active">
    <div class="responsive-table">
      <div class="loading min-h-300" v-if="loading">
        <LoadingAnim />
      </div>
      <table v-else class="table table-borderless">
        <thead class="">
          <tr>
            <th scope="col">{{ $t('generic-str.date') }}</th>
            <!-- Data -->
            <th scope="col">{{ $t('generic-str.type') }}</th>
            <!-- Tipo -->
            <th scope="col">{{ $t('generic-str.lbl-sender') }}</th>
            <!-- Remetente -->
            <th scope="col">{{ $t('generic-str.lbl-recipient') }}</th>
            <!-- Destinatario -->
            <th scope="col">{{ $tc('generic-str.message', 1) }}</th>
            <!-- Mensagem -->
            <th scope="col">{{ $tc('generic-str.part', 2) }}</th>
            <!-- Partes -->
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="message in messages"
            :key="message.id"
            :to="`history/${message.id}`"
          >
            <td data-label="Data">
              <div>
                <router-link :to="`history/${message.id}`">
                  {{ message.scheduled_at }}
                </router-link>
              </div>
            </td>
            <td data-label="Tipo">
              <template v-if="message.direction == 'outbound'">
                {{ $t('generic-str.send-2') }}
              </template>
              <template v-else-if="message.direction == 'inbound'">
                Resposta
              </template>
            </td>
            <td data-label="Remetente">{{ message.from }}</td>
            <td data-label="Destinatário">{{ message.to }}</td>
            <td
              data-label="Mensagem"
              class="max-200"
              v-tooltip.top="`${message.body}`"
            >
              {{ message.body }}
            </td>
            <td data-label="Partes">{{ message.segments }}</td>
            <td data-label="Status">
              <span
                v-if="message.status == 'accepted'"
                class="badge badge-light"
                >{{ $tc('generic-str.status.lbl-pending', 1) }}</span
              >
              <!-- Pendente -->
              <span
                v-else-if="message.status == 'queued'"
                class="badge badge-secondary"
                >{{ $t('generic-str.status.lbl-queue') }}</span
              >
              <!-- Em Fila -->
              <span
                v-else-if="message.status == 'sending'"
                class="badge badge-accent"
                >{{ $t('generic-str.status.lbl-sending') }}</span
              >
              <!-- Enviando -->
              <span
                v-else-if="message.status == 'sent'"
                class="badge badge-info"
                >{{ $t('generic-str.status.lbl-sent') }}</span
              >
              <!-- Enviado -->
              <span
                v-else-if="message.status == 'failed'"
                class="badge badge-danger"
                v-tooltip.top="
                  `${$t('sms.infos.filters.status.lbl-error')} ${
                    message.error_code
                  }: ${message.error_message}`
                "
                >{{ $tc('app.failed') }}</span
              >
              <!-- Falha -->
              <span
                v-else-if="message.status == 'delivered'"
                class="badge badge-success"
                >{{ $tc('app.delivered') }}</span
              >
              <!-- Entregue -->
              <span
                v-else-if="message.status == 'undelivered'"
                class="badge badge-dark"
                v-tooltip.top="
                  $t('generic-str.status.lbl-error') +
                  ` ${message.error_code}: ${message.error_message}`
                "
                >{{ $t('generic-str.status.lbl-unavailable') }}</span
              >
              <!-- Indisponivel -->
              <span
                v-else-if="message.status == 'received'"
                class="badge badge-success"
                >{{ $tc('generic-str.status.lbl-response', 1) }}</span
              >
              <!-- Resposta -->
            </td>
          </tr>
        </tbody>
      </table>
      <div class="row">
        <pagination :lastPage="pages" @change="fetch" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import SmsService from '@/services/sms.service';
import Pagination from '@/components/Pagination.vue';
import LoadingAnim from '@/components/rebranding/animLottie/Loading.vue';

export default {
  name: 'PipelineHistoryTab',
  components: {
    // PageHeader,
    Pagination,
    LoadingAnim,
  },
  props: {
    filters: {
      type: Object,
    },
    startDate: {
      type: String,
    },
    endDate: {
      type: String,
    },
  },
  data() {
    return {
      options: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        maxSpan: {
          days: 31,
        },
        locale: {
          format: 'DD/MM/YYYY',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
      loading: true,
      messages: [],
      form: {
        id: '',
        status: '',
        from: '',
        to: '',
        scheduled_at: {
          gte: '',
          lte: '',
        },
        order_by: ['scheduled_at', 'desc'],
        page: 1,
      },
      pages: 1,
      exporting: false,
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch(page = 1) {
      this.loading = true;
      if (this.$route.params.campaignId) {
        this.filters.campaign_id = this.$route.params.campaignId;
      }
      this.$store
        .dispatch('fetchSmsMessages', {
          filter: {
            ...this.filters,
            scheduled_at: {
              gte: this.startDate,
              lt: this.endDate,
            },
          },
          page,
        })
        .then((response) => {
          this.messages = response.data;
          this.pages = response.last_page;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    exportData(format) {
      this.loading = true;

      this.$store
        .dispatch('exportSmsMessages', {
          filter: {
            ...this.filters,
            scheduled_at: {
              gte: this.startDate,
              lt: this.endDate,
            },
          },
          format,
        })
        .then(() => {
          this.$toast.show({
            title: this.$t('generic-str.success'),
            content: this.$t('warnings.toast.report'),
            type: 'success',
          });
          this.$router.push({ name: 'PipelineReportExports' });
        })
        .finally(() => {
          this.fetched = true;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.btn-search-id {
  height: 35px;
}
.btn-export {
  margin-left: 1px;
  padding: 8px 20px !important;
}
.m-top-10 {
  margin-top: 10px;
}

/* Rebranding */
.rebranding .card.borderless {
  box-shadow: unset !important;
}

.form-group {
  .icon_btn {
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid var(--form-border);
    border-left-color: transparent;
    border-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    span {
      color: var(--gray-font-color);
    }
  }
}
.darkmode .form-group .icon_btn {
  background-color: var(--form-input);
  border-color: var(--form-border);
  border-left-color: transparent;
}
.icon-success {
  color: #6eda2c;
}
.icon-failed {
  color: #ff0000;
}
</style>
